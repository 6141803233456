.jss80 {
	@media only screen and (min-width: 600px) {
		height:80%
	}
	@media only screen and (max-width:600px) {
		height:100%
	}
}
.MuiDialogContent-root .jss79 {
	height: 80%
}
