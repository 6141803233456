@font-face {
    font-family: "NeueHaasDisplayBold";
    src: url(../font/NeueHaasDisplayBold.ttf);
    font-weight: 700;
}
@font-face {
    font-family: "NeueHaasDisplayLight";
    src: url(../font/NeueHaasDisplayLight.ttf);
    font-weight: 300;
}
@font-face {
    font-family: "NeueHaasDisplayLight";
    src: url(../font/NeueHaasDisplayLight.ttf);
    font-weight: 400;
}
@font-face {
    font-family: "NeueHaasDisplayMedium";
    src: url(../font/NeueHaasDisplayMedium.ttf);
    font-weight: 600;
}
@font-face {
    font-family: "NeueHaasDisplayRoman";
    src: url(../font/NeueHaasDisplayRoman.ttf);
    font-weight: 600;
}

/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
    width: 6px; /* 1px wider than Lion. */
    /* This is more usable for users trying to click it. */
    border-radius: 100px;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
    width: 6px; /* 1px wider than Lion. */
    /* This is more usable for users trying to click it. */
    border-radius: 100px;
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
    /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
    border-radius: 100px;
}

::-webkit-scrollbar-thumb:vertical:hover {
    /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
    border-radius: 100px;
}

#drawerId::-webkit-scrollbar-thumb:vertical {
    /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
    border-radius: 100px;
}

::-webkit-scrollbar-thumb:vertical:active {
    border-radius: 100px;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
    display: none;
}
body {
    font-family: "NeueHaasDisplayLight" !important;
    min-height: 100vh;
    min-height: -moz-available; /* WebKit-based browsers will ignore this. */
    min-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
}
.parent-body {
    display: flex;
    flex: 1;
    justify-content: center;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
}
html {
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input::-ms-reveal,
input::-ms-clear {
    display: none;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

*:focus {
    outline: 0 !important;
}

.chatItem {
    width: fit-content;
    width: -moz-fit-content;
}

*{
    scrollbar-color: #5100FF transparent;
    scrollbar-width: thin;
}
