body {
  /* background-color: #000000 !important; */
  margin: 0;
  font-family: 'NeueHaasDisplayLight';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

code {
  font-family: 'NeueHaasDisplayLight';
}

#root { 
  height: 100%;
}


@media only screen and (orientation: landscape) {
  body {
    overflow: auto;
  }
}

.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader-path {
  stroke-dasharray: 68,7;
  stroke-dashoffset: -6;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,75;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 53,75;
    stroke-dashoffset: -21;
  }
  100% {
    stroke-dasharray: 53,75;
    stroke-dashoffset: -74;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1,75;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 53,75;
    stroke-dashoffset: -21;
  }
  100% {
    stroke-dasharray: 53,75;
    stroke-dashoffset: -74;
  }
}

@media(min-width: 815px){
  .loader-path {
    stroke-dasharray: 115,11;
    stroke-dashoffset: -10;
    stroke-width:2;
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1,126;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89,126;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89,126;
      stroke-dashoffset: -124;
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1,126;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89,126;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89,126;
      stroke-dashoffset: -124;
    }
  }
}
